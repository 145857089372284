import './scss/style.scss';
import 'bootstrap';

import { WOW } from 'wowjs';
import { Swiper, Autoplay, Pagination } from 'swiper/js/swiper.esm.js';

Swiper.use([Autoplay, Pagination]);

/*Se inicializa el plugin para las animaciones*/
var wow = new WOW({
    live: false,
    scrollContainer: null
});

/*Todos los "load" cargan los bloques recurrentes*/
$('header').load("header.html");
$('footer').load("footer.html");
$('.block-contact-us').load("block-contact-us.html", function() {
    wow.init();
});
$('#block-buttons-internal').load("block-buttons-internal.html", function() {
    var item = $('#block-buttons-internal').data('active');
    $(item).addClass('active');
    wow.init();
});

/*Script para el slider del home*/
if ($.contains(document.body, document.getElementById('slider-main'))) {

    var mySwiper = new Swiper('#slider-main', {
        loop: true,
        autoplay: {
            delay: 5000,
        },
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true
        },
    });

    $(document).on('click', '.block-banner-main .btn-light', function(event) {
        event.preventDefault();

        var item = $(this).data('item');

        if (item) {
            mySwiper.slidePrev();
        } else {
            mySwiper.slideNext();
        }

    });
}

/*Se agregan las animaciones para toda la pagina que no cargan de menera recurrente*/
wow.init();